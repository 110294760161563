import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LinkButton from '../components/Shared/LinkButton';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mx-auto px-6 py-6 max-w-screen-xl mb-16 flex flex-col items-center">
      <h1 className="text-3xl md:text-5xl font-bold text-periwinkle-base text-center mb-3">We've updated our website.</h1>
      <p className="text-2xl mb-7" style={{ maxWidth: 560 }}>Please select an option below:</p>
      <div className="mt-2 flex flex-col md:flex-row">
        <LinkButton.Periwinkle
          className="inline-block mb-6 md:mb-0 md:mr-4 text-center"
          to="/"
        >
          VCU Home
        </LinkButton.Periwinkle>
        <LinkButton.Periwinkle
          className="inline-block mb-6 md:mb-0 md:mr-4 text-center"
          to="https://www.cue-branch.com/vcu/Login.aspx"
        >
          Online Banking Login
        </LinkButton.Periwinkle>
        <LinkButton.Periwinkle
          className="inline-block text-center"
          to="/contact-us"
        >
          Contact Us
        </LinkButton.Periwinkle>
      </div>

    </div>

  </Layout>
);

export default NotFoundPage;
